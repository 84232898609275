<template>
  <div>
    <v-toolbar flat dense floating color="transparent">
      <v-toolbar-title>{{ $t('widgets.planeteOnlineNews.name') }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text class="pt-2" v-if="!moving">

      <v-hover v-slot="{hover}" v-for="(newsletter, i) in getRealNews" :key="i+'a'">
        <v-card dense class="mb-4" hover
                @click="goToCom(newsletter._id)">
          <v-card-subtitle class="mt-0 mb-0 pb-0">
            {{ lang == "fr" ? newsletter.label : newsletter.label_en }}
          </v-card-subtitle>
          <v-card-subtitle class="pt-1 pb-0">
            <template v-for="(module, i) in getUniqueModule(newsletter.module)">
              <planete-icon class="mr-2" height="24" width="24" :key="i" v-if="module" :dark="$vuetify.theme.dark">{{ getIcon(module) }}</planete-icon>
            </template>
            <br>
            <v-chip x-small :color="!seen(newsletter) ? 'blue' : ''" :class="['rounded', 'py-1', 'px-2', {'white--text': !seen(newsletter)}]">{{ !seen(newsletter) ? $t("actions.notSeen") : $t('actions.seen') }}</v-chip>
          </v-card-subtitle>

          <v-card-text class="text-center pt-0">
            <v-btn text x-small class="mt-1 float-right">
              {{ $t("widgets.planeteOnlineNews.seeMore")}}
              <v-icon x-small right>mdi-page-next</v-icon>
            </v-btn>
            <br>
          </v-card-text>


        </v-card>
      </v-hover>

      <v-hover v-slot="{hover}" v-for="(newsletter, i) in news" :key="i+'b'">
        <v-card dense class="pt-4 mb-4" hover
                @click="$router.push({name: 'PlaneteOnlineNews', params: {id: newsletter.id}})">
          <div class="text-center">
            <planete-icon height="32" width="32">planete-online</planete-icon>
          </div>
          <v-card-title class="news-title text-center pt-2">
            <span>{{ newsletter.title }}</span>
          </v-card-title>
          <v-card-text class="text-justify pb-1 font-12">
            {{ tease(newsletter.text) }}<br>
          </v-card-text>
          <v-card-text class="text-center pt-0">
            <v-btn x-small text>
              {{ $t("widgets.planeteOnlineNews.seeMore")}}
              <v-icon x-small right>mdi-page-next</v-icon>
            </v-btn>
          </v-card-text>


        </v-card>
      </v-hover>

    </v-card-text>
  </div>
</template>

<script>
import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import CommunicationService from "@/Services/Communication";

export default {
  name: "WidgetPlaneteOnlineNews",
  props: {
    moving: Boolean,
  },
  computed: {
    ...Vuex.mapState(['widgetBoard', 'modules', 'communication']),
    news() {
      return [
        {
          id: "welcome",
          text: "Chers Clients, Depuis sa création, notre logiciel PL@N.net n’a pas cessé d’évoluer et nous tenons à vous remercier pour votre participation active dans cette belle aventure."
        }
      ]
    },
    lang() {
      return this.$i18n.locale == "en" ? "en" : "fr"
    },
    getRealNews() {
      return this.communication.news.slice().filter(com => {
        let date = com.date
        if (date && !(date instanceof Date)) {
          date = new Date(date)
        }
        let expiration = null
        if (com.expiration) {
          expiration = new Date(com.expiration)
        }

        let rights
        if (com.module.length) {
          let allSubModules = []
          this.modules.forEach(mod => {
            mod.subModules.map(a => a.path).forEach(submod => allSubModules.push(submod))
          })
          rights = com.module.some(comMod => // Hé attention, je suis pas comMod
              allSubModules.indexOf(comMod.path) !== -1
          )
        } else {
          rights = true
        }

        return date && new Date() >= date && (!com.module || rights) && (!expiration || expiration > new Date())
        // Retourne true si :
        // Il y a une date et qu'elle est dans le passé
        // &&
        // Qu'il n'y a aucun module de lié (donc com globale) ou qu'il y a un/plusieurs modules dont il a les droits
      })
    },
  },
  methods: {
    getUniqueModule(data) {
      let allModules = []
      for (let m of data) {
        let map = allModules.map(a=>a.icon)
        let mod = this.getModule(m)
        if (mod && map.indexOf(mod.icon) === -1) {
          allModules.push(mod)
        }
      }
      return allModules
    },
    getIcon(module) {
      return module ? module.icon : ""
    },
    tease(str) {
      return str.substr(0, 160) + "..."
    },
    seen(com) {
      let seens = this.$store.getters.getWidgetDatas("planete-online-news", "seen")
      return seens.indexOf(com._id) > -1
    },
    goToCom(comId) {
      this.$router.push({name: 'PlaneteOnlineNews', params: {id: comId}})
    },
    getModule(module) {
      let ret = this.modules.find(mod => {
        return mod.subModules.some(submod => {
          return submod.path && submod.path == module.path
        })
      })
      if (!ret) {
        ret = null
      }
      return ret
    }
  }
}
</script>

<style scoped>
.font-12 {
  font-size: 12px;
}

.news-title {
  font-size: 1.2em;
  line-height: 1.2em;
  word-break: break-word;
}
</style>