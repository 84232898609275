import Api from "@/Services/Api";
import Request from "@/Services/FetchOrchestrator/Request";
import FetchOrchestrator from "@/Services/FetchOrchestrator/FetchOrchestrator";

export default {
    async getNews() {
        let request = new Request("/communication/news", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async postNews(news) {
        let response = await Api().post('/communication/news', {...news})
        return response.data
    },
    async removeNews(id) {
        let params = {
            id: id
        }
        let response = await Api().delete("/communication/news", {params: params})
        return response.data == "OK"
    },
    async editNews(news) {
        let response = await Api().put("/communication/news", {newsToUpdate: news})
        return response.data
    },
    async getExclusionList() {
        let response = await Api().get("/communication/exclusion-list")
        return response.data
    },
    async postExclusionList(data) {
        let response = await Api().post("/communication/exclusion-list", {exclusionList: data})
        return response.data
    },
    async putExclusionList(data) {
        let response = await Api().put("/communication/exclusion-list", {exclusionList: data})
        return response
    },
    async removeExclusionList(id) {
        let response = await Api().delete("/communication/exclusion-list", {params: {exclusionList: id}})
        return response
    }
}