import { render, staticRenderFns } from "./WidgetPlaneteOnlineNews.vue?vue&type=template&id=e5e697ba&scoped=true&"
import script from "./WidgetPlaneteOnlineNews.vue?vue&type=script&lang=js&"
export * from "./WidgetPlaneteOnlineNews.vue?vue&type=script&lang=js&"
import style0 from "./WidgetPlaneteOnlineNews.vue?vue&type=style&index=0&id=e5e697ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5e697ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VChip,VHover,VIcon,VToolbar,VToolbarTitle})
